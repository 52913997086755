import * as React from 'react';
// components
// routing

function AdminClient() {
  return (
   <p>Admin -  Add Client</p>
  );
}

export default AdminClient;