import * as React from 'react';
// routing
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Clients from './Pages/Clients';
import Live from './Pages/Live';
import AdminClient from './Pages/AdminClient';
import NotFound from './Components/NotFound';
import NavBar from './Components/NavBar';

function App() {

  return (
    <>
    <NavBar />
    <div className="App">
      </div>
    <Routes>
       <Route path="/" element={<Dashboard />} />
       <Route path="/clients" element={<Clients />} />
       <Route path="/live" element={<Live/>} />
       <Route path="/admin/add-client" element={<AdminClient />} />
       <Route path="*" element={<NotFound />} />
    </Routes>
 </>
  );
}

export default App;