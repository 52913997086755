// src/pages/Live.js
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';

const Live = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch('https://lead-api.hileads.net/reports');
    const data = await response.json();
    setRows(data.live_feed);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { field: 'date', headerName: 'Date', width: 200},
    { field: 'client_name', headerName: 'Client' , flex: 1},
    { field: 'source_platform', headerName: 'Platform', flex: 1},
    { field: 'utm_ad_id', headerName: 'UTM Ad ID', flex: 1},
    { field: 'utm_source', headerName: 'UTM Source', flex: 1},
    { field: 'source_lead_id', headerName: 'Lead ID' , flex: 1},
    { field: 'source_device', headerName: 'Device', flex: 1},
    { field: 'source_os', headerName: 'OS', flex: 1}
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <h1>Live</h1>
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGrid 
        rows={rows}
        columns={columns}
        pageSize={10}
        getRowId={(row) => row.date}
        disableRowSelectionOnClick
        autoHeight {...rows}
        pageSizeOptions={[10,25,50,100]}
        />
      )}
    </div>
  );
};

export default Live;
