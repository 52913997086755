import * as React from 'react';
// components
// routing

function NotFound() {
  return (
   <p>404 - Not Found</p>
  );
}

export default NotFound;