import * as React from 'react';
// components
// routing

function Dashboard(props) {
  return (
    //print out the data from the props in li elements only if props.data is not null
    <div>
      <h1>Dashboard</h1>
      {props.data && (
        <ul>
          {props.data.map((item) => (
            <li key={item.id}>{item.name} - {item.email} - {item.phone}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dashboard;