import { NavLink } from 'react-router-dom';

const NavBar = () => {
 return (
 <nav>
       <ul>
          <li>
             <NavLink to="/">Dashboard</NavLink>
          </li>
          <li>
             <NavLink to="/clients">Clients</NavLink>
          </li>
          <li>
             <NavLink to="/live">Live</NavLink>
          </li>
          <li>
             <NavLink to="/admin/add-client">Add Client</NavLink>
          </li>
       </ul>
 </nav>
 );
};

export default NavBar;